jQuery(document).ready(function () {
  jQuery("#services-accordian .service-desc").on(
    "show.bs.collapse",
    function () {
      var id = jQuery(this).attr("data-service-id");

      // Hide all asides
      jQuery(".card-aside").removeClass("inplace");

      // Show this aside
      jQuery("#card-aside-" + id).addClass("inplace");

      var element_id = jQuery(this).attr("id");

      if (jQuery(document).width() <= 991) {
        setTimeout(function () {
          jQuery("html, body").animate(
            {
              scrollTop: jQuery("#" + id + "-button").offset().top - 10,
            },
            200
          );

          // Close headroom
          setTimeout(function () {
            headroom.unpin();
          }, 250);
        }, 300);
      }
    }
  );
});

jQuery(document).ready(function () {

  var controller = new ScrollMagic.Controller();
  
  var animateTwoColumn = Array.prototype.slice.call(
    document.querySelectorAll(".two-col-fade")
  );

  animateTwoColumn.forEach(function (self) {
    jQuery("#" + self.id).imagesLoaded(function () {
      var two_column_col1 = self.querySelectorAll(
        ".two-col-fade .col-first .inner"
      );
      var two_column_col2 = self.querySelectorAll(".two-col-fade .col-second");

      var twoColumnScene = new TimelineMax();

      twoColumnScene
        .fromTo(
          two_column_col1,
          0.8,
          { opacity: 0, y: 10 },
          {
            opacity: 1,
            y: 0,
          }
        )
        .fromTo(
          two_column_col2,
          0.8,
          { opacity: 0, y: 10 },
          {
            opacity: 1,
            y: 0,
          },
          0.25,
          "-=.25"
        );

      var two_column_scene = new ScrollMagic.Scene({
        triggerElement: self,
        triggerHook: "onEnter",
        duration: 0,
        offset: 0,
      })
        .setTween(twoColumnScene)
        .setClassToggle(self, "active")
        .addTo(controller);
    });
  });
});
